import React from "react"

import BarStep from "./partials/bar-step"
import CarAlreadyAssociated from "../car/car-already-associated"
import Layout from "../../components/layout";

const AddCarAlreadyPage = () => {
  return (
    <div>
      <Layout>
        <BarStep selected="step1" />
        <CarAlreadyAssociated currentPage="profile"/>
      </Layout>
    </div>
  )
};

export default AddCarAlreadyPage
